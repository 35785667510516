import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../appRedux/store'
import { GROUP_MENU, PATH } from '../../../constants/common'

export interface LayoutState {
  selectedMenuItem: string
  selectedMenuGroup: string[]
  inputSearch: string
  rowKey: string[]
  rowRecord: any[]
  isFullScreenRank: boolean
  collapsed: boolean
}

const initialState: LayoutState = {
  selectedMenuItem: PATH.ADMIN_DASHBOARD,
  selectedMenuGroup: [GROUP_MENU.HOME_GROUP],
  inputSearch: '',
  rowKey: [],
  rowRecord: [],
  isFullScreenRank: false,
  collapsed: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload
    },
    setGroupMenu: (state, action) => {
      state.selectedMenuGroup = action.payload
    },

    setInputSearch: (state, action) => {
      state.inputSearch = action.payload
    },
    resetInputSearch: (state) => {
      state.inputSearch = ''
    },
    changeRowKey: (state, action) => {
      state.rowKey = action.payload
    },
    changeRowRecord: (state, action) => {
      state.rowRecord = action.payload
    },
    changeIsFullScreenRank: (state, action) => {
      state.isFullScreenRank = action.payload
    },
    changeCollapsed: (state, action) => {
      state.collapsed = action.payload
    },
  },
})

export const {
  setMenuItem,
  setGroupMenu,
  setInputSearch,
  changeRowKey,
  changeRowRecord,
  changeIsFullScreenRank,
  changeCollapsed,
} = layoutSlice.actions

export const selectMenuItem = (state: RootState) =>
  state.layout.selectedMenuItem
export const selectGroupMenu = (state: RootState) =>
  state.layout.selectedMenuGroup
export const selectInputSearch = (state: RootState) => state.layout.inputSearch
export const selectIsFullScreenRank = (state: RootState) =>
  state.layout.isFullScreenRank
export const selectCollapsed = (state: RootState) => state.layout.collapsed
export const selectRowKey = (state: RootState) => state.layout.rowKey
export const selectRowRecord = (state: RootState) => state.layout.rowRecord

export default layoutSlice.reducer
