import { FundOutlined, BarChartOutlined } from '@ant-design/icons'

export const GROUP_MENU = Object.freeze({
  HOME_GROUP: 'HomeGroup',
})

export const BASE_PAGE = {
  ADMIN_PAGE: 'admin',
  PAGE: '',
}

export const PATH_NAME = {
  HOME: `${BASE_PAGE.PAGE}`,
  BASE_REGISTER: `${BASE_PAGE.PAGE}/register`,
  REGISTER: `${BASE_PAGE.PAGE}/register/2025`,
  GEN_QRCODE: `${BASE_PAGE.PAGE}/gen-qrcode`,
}

export const REGISTER_PATH = {
  KOJITEN: `${PATH_NAME.REGISTER}/kojiten`,
  HANBAITEN: `${PATH_NAME.REGISTER}/hanbaiten`,
  MAKER: `${PATH_NAME.REGISTER}/maker`,
  OTHER: `${PATH_NAME.REGISTER}/other`,
}

export const GEN_QRCODE_PATH = {
  KOJITEN: `${PATH_NAME.GEN_QRCODE}?type=kojiten`,
  HANBAITEN: `${PATH_NAME.GEN_QRCODE}?type=hanbaiten`,
  MAKER: `${PATH_NAME.GEN_QRCODE}?type=maker`,
  OTHER: `${PATH_NAME.GEN_QRCODE}?type=other`,
}

export const ADMIN_PATH = {
  ADMIN_HOME: `/${BASE_PAGE.ADMIN_PAGE}`,
  ADMIN_LOGIN: `/${BASE_PAGE.ADMIN_PAGE}/login`,
  ADMIN_DASHBOARD: `/${BASE_PAGE.ADMIN_PAGE}/dashboard`,
  ADMIN_RANKING: `/${BASE_PAGE.ADMIN_PAGE}/ranking`,
  ADMIN_SCAN: `/${BASE_PAGE.ADMIN_PAGE}/scan`,
}

export const PATH = {
  ...PATH_NAME,
  ...ADMIN_PATH,
  FORBIDDEN: '/forbidden',
}

export const ROUTES = Object.freeze({
  ADMIN_PRIVATE: [
    {
      path: PATH.ADMIN_HOME,
      element: 'admin/AdminHome',
    },
    {
      path: PATH.ADMIN_DASHBOARD,
      element: 'admin/DashboardPage',
    },
    {
      path: PATH.ADMIN_RANKING,
      element: 'admin/RankingPage',
    },
    {
      path: PATH.ADMIN_SCAN,
      element: 'admin/ScanPage',
    },
  ],
  PUBLIC: [
    // { path: 'user', element: 'EventOverView' },
    {
      path: PATH_NAME.REGISTER,
      element: 'user/EventOverView',
    },
    {
      path: REGISTER_PATH.KOJITEN,
      element: 'user/RegisterPage',
    },
    {
      path: REGISTER_PATH.HANBAITEN,
      element: 'user/RegisterPage',
    },
    {
      path: REGISTER_PATH.MAKER,
      element: 'user/RegisterPage',
    },
    {
      path: REGISTER_PATH.OTHER,
      element: 'user/RegisterPage',
    },
    { path: PATH.ADMIN_LOGIN, element: 'admin/LoginPage' },
    { path: PATH_NAME.GEN_QRCODE, element: 'user/GenQRCodePage' },
    { path: '*', element: 'NotFoundPage' },
  ],
})

export const HOME_MENU = [
  {
    path: PATH.ADMIN_DASHBOARD,
    name: '予約者・来場者一覧',
    icon: FundOutlined,
  },
  {
    path: PATH.ADMIN_RANKING,
    name: '動員ランキング',
    icon: BarChartOutlined,
  },
]

export const STORAGE_ITEM = Object.freeze({
  ACCESS_TOKEN: 'accessToken',
  USER_INFO: 'userInfo',
})

export const COOKIE_ITEM = Object.freeze({
  TOKEN: 'user_token',
  ADMIN_TOKEN: 'admin_token',
})

export const USER_ROLE = Object.freeze({
  ADMIN: 'admin',
  VIEWER: 'user',
})

export const TYPE_USER = Object.freeze({
  KOJITEN: 'kojiten',
  HANBAITEN: 'hanbaiten',
  MAKER: 'maker',
  OTHER: 'other',
})

export const NAME_USER = Object.freeze({
  KOJITEN: '工事店・工務店',
  HANBAITEN: '販売店',
  MAKER: 'メーカー',
  OTHER: 'その他',
})

export const NAME_TYPE_MAKER = Object.freeze({
  MIRAI_MAKER: 'みらい市出展メーカー',
  OTHERS: 'みらい市ご見学来場',
})

export const TYPE_MAKER = Object.freeze({
  MIRAI_MAKER: 'MIRAI_MAKER',
  OTHERS: 'OTHERS',
})

export const COLORS_USER = Object.freeze({
  KOJITEN: '#d4eaff',
  HANBAITEN: '#ffeae6',
  MAKER: '#fff2cc',
  OTHER: '#e2f0d9',
})

export const HEADERS_CSV_VIEWER = [
  { label: '受付番号', key: 'index' },
  { label: 'カテゴリー', key: 'userType' },
  { label: 'ご紹介店名', key: 'hanbaitenName' },
  { label: 'メーカー種類', key: 'makerType' },
  { label: '会社名', key: 'company' },
  { label: '氏名', key: 'fullname' },
  { label: 'ふりがな', key: 'furi' },
  { label: '住所', key: 'address' },
  { label: 'メール', key: 'email' },
  { label: '電話番号', key: 'phoneNumber' },
  { label: 'ステータス', key: 'joinedEventAt' },
]
