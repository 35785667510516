import { ApolloLink } from '@apollo/client'
import Cookies from 'js-cookie'
import { COOKIE_ITEM } from '../constants/common'

export const authLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get(COOKIE_ITEM.ADMIN_TOKEN)

  operation.setContext(({ headers }) => ({
    headers: {
      authorization: `Bearer ${token}`, // however you get your token
      ...headers,
    },
  }))
  return forward(operation)
})
