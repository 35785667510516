import React, { useEffect, useRef } from 'react'
import { ExclamationCircleFilled, LogoutOutlined } from '@ant-design/icons'
import { Button, Layout, Menu, MenuProps, Modal } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../appRedux/hooks'
import { HOME_MENU, PATH } from '../../../constants/common'
import * as Styled from './Layout.style'
import LayoutHeader from './LayoutHeader'
import {
  changeCollapsed,
  selectCollapsed,
  selectGroupMenu,
  selectIsFullScreenRank,
  selectMenuItem,
  setGroupMenu,
  setMenuItem,
} from './LayoutSlice'
import { logoutAdmin } from '../login/adminLoginSlice'

const { confirm } = Modal

type MenuItemProsType = {
  path: string
  name: string
  icon?: any
}
declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>
    msExitFullscreen?: () => Promise<void>
    webkitExitFullscreen?: () => Promise<void>
    webkitFullscreenElement?: Element
  }
}

export default function AdminLayout({ children }: { children: JSX.Element }) {
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedMenuItem = useAppSelector(selectMenuItem)
  const selectedMenuGroup = useAppSelector(selectGroupMenu)
  const IsFullScreenRank = useAppSelector(selectIsFullScreenRank)
  const collapsed = useAppSelector(selectCollapsed)
  const divRef = useRef<HTMLDivElement>(null)

  const items: MenuProps['items'] = HOME_MENU.map((item: MenuItemProsType) => ({
    key: item.path,
    icon: React.createElement(item.icon),
    label: item.name,
  }))

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key)
  }

  const handleLogoutAdmin = () => {
    confirm({
      title: '確認',
      icon: <ExclamationCircleFilled />,
      content: 'ログアウトしてもよろしいですか。',
      onOk() {
        dispatch(logoutAdmin())
        navigate(PATH.ADMIN_LOGIN)
      },
    })
  }

  useEffect(() => {
    dispatch(setMenuItem(pathname))
  }, [pathname])

  const handleFullscreen = () => {
    if (divRef.current) {
      const divObj: any = divRef.current
      if (divObj?.requestFullscreen) {
        divObj.requestFullscreen()
      } else if (divObj?.msRequestFullscreen) {
        divObj.msRequestFullscreen()
      } else if (divObj?.mozRequestFullScreen) {
        divObj.mozRequestFullScreen()
      } else if (divObj?.webkitRequestFullscreen) {
        divObj.webkitRequestFullscreen()
      }
    }
  }

  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
  }

  useEffect(() => {
    if (IsFullScreenRank) {
      handleFullscreen()
    } else {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        closeFullscreen()
      }
    }
  }, [IsFullScreenRank])

  return (
    <Styled.LayoutWrapper>
      <Styled.CustomSider
        collapsedWidth={70}
        // collapsible
        collapsed={collapsed}
        onCollapse={(value: boolean) => dispatch(changeCollapsed(value))}
      >
        {/* <Styled.ButtonCollapse onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </Styled.ButtonCollapse> */}
        <Styled.LogoWrapper>
          <Styled.FormTitle
            className={collapsed ? 'header-title--close' : 'header-title--open'}
          >
            <p>北海道みらい市</p>
            <span>来場予約管理サイト</span>
          </Styled.FormTitle>
        </Styled.LogoWrapper>

        <Menu
          onClick={onClick}
          mode='inline'
          defaultSelectedKeys={[selectedMenuItem]}
          selectedKeys={[selectedMenuItem]}
          onSelect={({ key }) => {
            dispatch(setMenuItem(key))
          }}
          openKeys={selectedMenuGroup}
          onOpenChange={(openKeys: string[]) =>
            dispatch(setGroupMenu(openKeys))
          }
          items={items}
        />
        <Styled.LogoutButton>
          <Button
            block
            icon={<LogoutOutlined />}
            danger
            size='large'
            onClick={handleLogoutAdmin}
          >
            {collapsed ? '' : 'ログアウト'}
          </Button>
        </Styled.LogoutButton>
      </Styled.CustomSider>
      <Layout>
        <LayoutHeader collapsed={collapsed} logoutAdmin={handleLogoutAdmin} />
        <Styled.CustomContent>
          <Styled.ContentWrapper ref={divRef}>{children}</Styled.ContentWrapper>
        </Styled.CustomContent>
      </Layout>
    </Styled.LayoutWrapper>
  )
}
