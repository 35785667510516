import { UserOutlined } from '@ant-design/icons'
// import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'src/appRedux/hooks'
// import { ADMIN_PATH } from 'src/constants/common'
import * as Styled from './Layout.style'
import { selectCollapsed } from './LayoutSlice'

type LayoutHeaderProps = {
  collapsed?: boolean
  logoutAdmin?: () => void
}

export default function LayoutHeader({}: LayoutHeaderProps) {
  // const { pathname } = useLocation()
  const collapsed = useAppSelector(selectCollapsed)

  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: (
  //       <Button
  //         block
  //         icon={<LogoutOutlined />}
  //         danger
  //         size='large'
  //         onClick={logoutAdmin}
  //       >
  //         {collapsed ? 'ログアウト' : 'ログアウト'}
  //       </Button>
  //     ),
  //   },
  // ]

  console.log('collapsed', collapsed)

  return (
    <Styled.CustomHeader>
      <Styled.TitleHeader
      // className={collapsed ? 'title-header-close' : 'title-header-open'}
      >
        {/* {pathname === ADMIN_PATH.ADMIN_DASHBOARD
          ? '予約者・来場者一覧'
          : '動員ランキング'} */}
        {/* {!collapsed ? '北海道みらい市' : '北海道みらい市'} */}
      </Styled.TitleHeader>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Styled.CustomName>Admin</Styled.CustomName>
        <Styled.CustomAvatar size='default' icon={<UserOutlined />} />
      </div>
    </Styled.CustomHeader>
  )
}
