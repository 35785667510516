export const spaces = {
  space00: '0.5rem',
  space01: '0.7rem',
  space10: '1rem',
  base: '1.15rem',
  space20: '2rem',
  space30: '2.5rem',
  space40: '3rem',
  space50: '3.5rem',
  space60: '4rem',
  space70: '4.5rem',
  space80: '5rem',
}
