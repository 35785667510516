import { useLocation } from 'react-router-dom'
import { COLORS_USER } from 'src/constants/common'
import * as Styled from './Layout.style'

export default function UserLayout({ children }: { children: JSX.Element }) {
  const { pathname, search } = useLocation()
  const checkBgColor = () => {
    if (pathname?.includes('kojiten') || search?.includes('kojiten')) {
      return COLORS_USER['KOJITEN']
    }
    if (pathname?.includes('hanbaiten') || search?.includes('hanbaiten')) {
      return COLORS_USER['HANBAITEN']
    }
    if (pathname?.includes('maker') || search?.includes('maker')) {
      return COLORS_USER['MAKER']
    }
    if (pathname?.includes('other') || search?.includes('other')) {
      return COLORS_USER['OTHER']
    }
  }

  return (
    <Styled.LayoutWrapper
      style={{
        backgroundColor: checkBgColor(),
      }}
    >
      <Styled.Wrapper>{children}</Styled.Wrapper>
    </Styled.LayoutWrapper>
  )
}
